import * as React from "react"
import Layout from "../components/layout"

const ThankYouPage = () => (
    <Layout>
      <div className="bg-gray-50">
        <div className="py-4 lg:py-6">
          <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
              Thank you!
            </h1>
            <p className="mt-6 text-xl text-gray-500 max-w-3xl">
              We really appreciate your interest! We&apos;re so excited to have you on board!
            </p>
            <p className="mt-6 text-xl text-gray-500 max-w-3xl">
              Our app is <span className="text-cyan-600 font-medium">so new</span> that we&apos;re still in a private beta. The waitlist prevents us from growing too quickly, so that we can keep the community safe and welcoming for all types of riders and trainers.
            </p>
            <p className="mt-6 text-xl text-gray-500 max-w-3xl">
              As soon as a spot opens up for you, you&apos;ll receive an email with an invitation to create an account.
            </p>
          </div>
        </div>
      </div>
    </Layout>
)

export default ThankYouPage
